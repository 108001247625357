//----------  slick slider
//------------------------------------------------------------------------------
.slick-slider {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	display: block;
	position: relative;
	touch-action: pan-y;
	user-select: none;
	.slick-track,
	.slick-list {
		transform: translate3d(0, 0, 0);
	}
	@include breakpoint(tablet) {
		padding: 0;
	}
	@include breakpoint(phone) {
		padding: 0;
	}
}

.slick-list {
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	&:focus {
		outline: none;
	}
	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-track {
	@extend %clearfix;
	display: block;
	left: 0;
	position: relative;
	top: 0;
	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	outline: 0;
	[dir='rtl'] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}
	&.dragging img {
		pointer-events: none;
	}
	.slick-initialized & {
		display: block;
	}
	.slick-loading & {
		visibility: hidden;
	}
	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow {
	&.slick-hidden {
		display: none;
	}
}

.slick-prev, .slick-next {
	font-size: 150px;
	position: absolute;
	top: 48%;
	transform: translate(0, -50%);
}
.slick-prev {
	left: 20px;
}
.slick-next {
	right: 15px;
}
.slick-dots {
	font-size: 0;
	text-align: center;
	li {
		margin: 4px;
		display: inline-block;
	}
	button {
		font-size: 0;
		width: 8px;
		height: 8px;
		border-radius: 100%;
		background-color: $white;
		display: inline-block;
	}
	.slick-active {
		button {
			background-color: #404040;
		}
	}
}
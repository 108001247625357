//---------- review
//------------------------------------------------------------------------------

.review {
	text-align: center;
	color: $white;
	font-size: 28px;
	padding: 90px 0;
	.wrap {
		max-width: 1316px;
	}
	.btn {
		margin-left: -5px;
	}
	@include media-breakpoint-only(lg) {
		font-size: 23px;
	}
	@include breakpoint(landscape) {
		font-size: 21px;
		padding: 50px 27px 0 30px;
		.btn {
			margin: 0;
			min-width: 276px;
		}
	}
	@include breakpoint(tablet) {
		padding: 55px 0;
		font-size: 16px;
		.row {
			padding: 0 10px;
		}
		.btn {
			margin: 0;
			min-width: 221px;
			height: 39px;
			font-size: 14px;
			line-height: 34px;
			&::after {
				font-size: 16px;
			}
		}
	}
	@include breakpoint(phone) {
		padding: 45px 0 26px;
		font-size: 16px;
		.row {
			padding: 0 28px;
		}
		.btn {
			margin: 0;
		}
	}
}
.review__col {
	height: 412px;
	padding: 0 11px;
	@include breakpoint(landscape) {
		height: 305px;
		padding: 0 6px;
	}
	@include breakpoint(tablet) {
		height: 233px;
		padding: 0 6px;
		margin-bottom: 21px;
	}
	@include breakpoint(phone) {
		height: 233px;
		margin-bottom: 21px;
	}
}
.review__img {
	padding: 107px 10px;
	background-size: cover;
	height: 100%;
	box-shadow: inset 300px 300px 0 300px rgba($black, 0.4);
	@include breakpoint(landscape) {
		padding: 80px 10px 70px;
	}
	@include breakpoint(tablet) {
		padding: 61px 6px 59px;
	}
	@include breakpoint(phone) {
		padding: 60px 6px 59px;
	}
}
.review__title {
	margin-bottom: 93px;
	@include breakpoint(landscape) {
		margin-bottom: 80px;
	}
	@include breakpoint(tablet) {
		margin-bottom: 55px;
	}
	@include breakpoint(phone) {
		margin-bottom: 35px;
	}
}
.review__subtitle {
	letter-spacing: 0.01em;
	margin-bottom: 3px;
	margin-left: -5px;
	@include breakpoint(landscape) {
		margin-left: 0;
	}
}
.review__text {
	letter-spacing: 0.006em;
	display: block;
	line-height: 28px;
	margin-bottom: 45px;
	margin-left: -5px;
	@include breakpoint(landscape) {
		line-height: 22px;
		margin-bottom: 30px;
		margin-left: 0;
	}
	@include breakpoint(tablet) {
		line-height: 16px;
		margin-bottom: 21px;
	}
	@include breakpoint(phone) {
		line-height: 16px;
		margin-bottom: 18px;
		margin-left: 0;
	}
}

//---------- highlights
//------------------------------------------------------------------------------
// sass-lint:disable no-important, no-vendor-prefixes
.highlights {
	background-color: $gray;
	padding: 39px 0;
	text-align: center;
	.slider__wrapper {
		padding: 0 25px;
		margin: 34px 0 0;
	}
	.slider__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.slick-slider {
		padding: 0 180px;
	}
	.slider__title {
		display: block;
		text-align: left;
		font-size: 29px;
		text-transform: uppercase;
		color: $purple;
		font-weight: 900;
		letter-spacing: -0.025em;
	}
	.slick-dots {
		margin: -1px 0 45px;
	}
	.btn {
		margin-left: -25px;
	}
	@include breakpoint(landscape) {
		.wrap, .slick-slider {
			padding: 0;
		}
		.slick-dots {
			margin: -2px 0 43px;
		}
	}
	@include breakpoint(tablet) {
		padding: 45px 0 33px;
		.wrap, .slick-slider {
			padding: 0;
		}
		.slider__wrapper {
			padding: 0 15px;
			margin-top: 37px;
		}
		.slider__title {
			font-size: 31px;
		}
		.slick-dots {
			margin: 12px 0 82px;
		}
		.btn {
			margin-left: 0;
		}
	}
	@include breakpoint(phone) {
		padding-top: 45px;
		padding-bottom: 35px;
		.slider__wrapper, .slick-slider {
			padding: 0;
		}
		.slider__wrapper {
			margin-top: 65px;
		}
		.slider__title {
			font-size: 17px;
			padding: 0 4%;
		}
		.slick-dots {
			margin: 32px 0 50px;
		}
		.btn {
			margin: 0;
		}
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			.slider__content {
				display: block;
			}
		}
	}
}
.slider__cetered {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	@include breakpoint(tablet) {
		padding-left: 10px;
	}
}
.slider__currency {
	display: block;
	text-align: left;
	font-size: 29px;
	text-transform: uppercase;
	color: $purple;
	font-weight: 900;
	letter-spacing: -0.025em;
	margin: -5px 0 20px;
	@include breakpoint(tablet) {
		font-size: 31px;
	}
	@include breakpoint(phone) {
		font-size: 17px;
		padding: 0 4%;
	}
}
.slider__value {
	font-size: 68px;
	color: $purple;
	font-weight: 900;
	float: left;
	margin-top: -5px;
	letter-spacing: -0.015em;
	@include breakpoint(landscape) {
		margin: 0;
	}
	@include breakpoint(tablet) {
		font-size: 86px;
	}
	@include breakpoint(phone) {
		font-size: 70px;
		padding: 0 4%;
	}
}
.slider__img {
	width: 100%;
	@include breakpoint(tablet) {
		width: 100%;
		margin-bottom: 18px;
	}
	@include breakpoint(phone) {
		width: 92%;
		margin: 0 auto;
	}
}
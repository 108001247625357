//----------  banner
//------------------------------------------------------------------------------
.banner {
	height: auto;
	position: relative;
	background-color: $purple;
	@include breakpoint(landscape) {
		padding-top: 106px;
	}
	@include breakpoint(tablet) {
		height: auto;
		padding-top: 106px;
	}
	@include breakpoint(phone) {
		height: auto;
		padding-top: 65px;
	}
}
.banner__video {
	// max-width: 100%;
	width: 100%;
	margin-bottom: -5px;
	@include breakpoint(tablet) {
		max-width: 100%;
		position: relative;
		transform: none;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
	@include breakpoint(phone) {
		max-width: 100%;
		position: relative;
		transform: none;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
}
.banner__content {
	@include breakpoint(desktop) {
		height: 90vh;
		position: absolute;
		top: 0;
	}
	height: 100%;
	width: 100%;
	position: relative;
}
.banner__title {
	color: $white;
	text-transform: uppercase;
	font-family: $font-bodoni;
	font-weight: 900;
	font-size: 180px;
	line-height: 140px;
	letter-spacing: -0.015em;
	@include breakpoint(desktop) {
		margin-top: 22px;
	}
	@include breakpoint(landscape) {
		font-size: 125px;
		line-height: 100px;
	}
	@include breakpoint(tablet) {
		font-size: 115px;
		line-height: 90px;
	}
	@include breakpoint(phone) {
		font-size: 50px;
		line-height: 38px;
	}
}
.fullscreen-btn {
	font-size: 30px;
	color: $white;
	position: absolute;
	right: 40px;
	@include breakpoint(desktop) {
		bottom: 55px;
	}
	@include breakpoint(landscape) {
		font-size: 25px;
		bottom: 37px;
	}
	@include breakpoint(tablet) {
		font-size: 25px;
		bottom: 49px;
	}
	@include breakpoint(phone) {
		font-size: 20px;
		right: 30px;
		bottom: 45px;
	}
}
.scroll-section {
	width: 28px;
	height: 28px;
	bottom: 23px;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 9px;
	border-radius: 100%;
	background-color: $white;
	color: $purple;
	position: absolute;
	@include breakpoint(desktop) {
		width: 40px;
		height: 39px;
		bottom: 31px;
		font-size: 12px;
	}
	@include breakpoint(tablet) {
		bottom: 33px;
	}
	@include breakpoint(phone) {
		bottom: 26px;
	}
}

//---------- business-model
//------------------------------------------------------------------------------
// sass-lint:disable no-important, no-vendor-prefixes
.business-model {
	text-align: center;
	padding: 50px 0 62px;
	.slider {
		padding: 30px 0 34px;
	}
	.slick-slider {
		padding: 0 100px;
	}
	.slick-slide > div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.slider__title {
		font-size: 40px;
		line-height: 40px;
		font-weight: 900;
		color: $purple;
		margin-bottom: 50px;
	}
	.slick-arrow {
		top: 46.5%;
	}
	.slick-dots {
		font-size: 0;
		text-align: center;
		li {
			margin: 4px;
			display: inline-block;
		}
		button {
			font-size: 0;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: #e3e3e3;
			display: inline-block;
		}
		.slick-active {
			button {
				background-color: #404040;
			}
		}
	}
	@include breakpoint(landscape) {
		.slick-slider {
			padding: 0;
		}
		.slick-dots {
			margin-top: 30px;
		}
	}
	@include breakpoint(tablet) {
		padding-bottom: 32px;
		.slider__container {
			padding: 0;
		}
		.slider {
			padding-bottom: 65px;
		}
		.slick-slide > div {
			display: flex;
			flex-direction: column;
			> * {
				display: block;
			}
		}
		.slider__wrapper {
			width: 100%;
		}
	}
	@include breakpoint(phone) {
		padding-bottom: 31px;
		.slick-slider {
			padding: 0;
		}
		.slider__container {
			padding: 0;
		}
		.slick-slide > div {
			display: flex;
			flex-direction: column;
			> * {
				display: block;
			}
		}
		.slick-dots {
			margin: 0 0 15px;
		}
		.slider__wrapper {
			width: 100%;
		}
		.slider__title {
			font-size: 16px;
			line-height: 16px;
			margin-bottom: 16px;
			&.slider__title--second {
				line-height: 18px;
				margin: -8px 0 20px;
			}
		}
		.slider__text {
			max-width: 170px;
			margin: 0 auto;
			font-size: 16px;
			line-height: 16px;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				max-width: 100%;
			}
		}
		.slider__number {
			font-size: 40px;
			margin-top: -15px;
		}
	}
}
.slider__figure-wrapper {
	@include breakpoint(tablet) {
		width: 80% !important;
	}
	@include breakpoint(phone) {
		width: 100% !important;
	}
}
.slider__figure {
	width: 497px;
	height: 497px;
	margin-top: 10px;
	margin-left: 110px;
	position: relative;
	@include breakpoint(landscape) {
		margin-left: 10px;
		margin-top: 15px;
	}
	@include breakpoint(tablet) {
		width: 497px;
		height: 497px;
		margin: 13px auto 25px;
	}
	@include breakpoint(phone) {
		width: 280px;
		height: 280px;
		margin: 0 auto;
		margin-top: 13px;
	}
}
.slider__text-wrapper {
	height: 100%;
	padding-left: 100px;
	margin-top: -10px;
	max-width: 470px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	@include breakpoint(landscape) {
		margin-top: 10px;
		padding-left: 95px;
	}
	@include breakpoint(tablet) {
		width: 100%;
		padding: 0;
		text-align: center;
	}
	@include breakpoint(phone) {
		margin: 15px auto 0;
		padding: 0;
		max-width: 600px;
		text-align: center;
	}
}
.slider__text {
	font-size: 20px;
	font-weight: 800;
	color: $purple;
}
.slider__number {
	font-family: $font-bodoni;
	font-size: 120px;
	@include breakpoint(tablet) {
		font-size: 80px;
		margin-top: -35px;
	}
}

.chart__text-wrapper {
	border-radius: 100%;
	display: flex;
	flex-wrap: wrap;
	width: 497px;
	height: 497px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	@include breakpoint(phone) {
		width: 280px;
		height: 280px;
	}
}
.slider__title--second {
	@include breakpoint(tablet) {
		font-size: 35px;
		line-height: 35px;
		margin: -10px 0 20px;
	}
}
.chart__row {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&.chart__row--one {
		order: 2;
	}
	&.chart__row--two {
		order: 1;
		.chart__box {
			&.chart__box--three {
				order: 2;
			}
			&.chart__box--four {
				order: 1;
			}
		}
	}
}
.chart__box {
	font-size: 16px;
	line-height: 16px;
	color: $white;
	font-weight: 900;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	&.chart__box--one, &.chart__box--two {
		.chart__text {
			margin-top: -15px;
		}
	}
	@include breakpoint(phone) {
		font-size: 9px;
		line-height: 9px;
	}
}
.chart__text {
	max-width: 134px;
	@include breakpoint(phone) {
		max-width: 74px;
	}
}
.chart-path {
	fill: $purple;
	stroke: $white;
}
.chart__number {
	font-size: 60px;
	font-family: $font-bodoni;
	fill: $white;
}
@media print {
	body {
		background: $white;
		color: $black;
		margin: 0;
		padding: 0;
		text-align: left;
	}
	a:link, a:visited {
		background: $white;
		color: $black;
		text-decoration: underline;
	}
	a:not([href='javascript:;']):after {
		content: '[' attr(href) ']';
	}
	h1, h2, h3, h4, h5, h6 {
		background: none;
		color: $black;
	}
	// add elements that are going to be hidden
	.temp {
		display: none;
	}
}

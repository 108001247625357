//----------  variables
//------------------------------------------------------------------------------

$font-default: Avenir, sans-serif;
$font-bodoni: Bodoni, sans-serif;

// Colors
$white: #fff;
$white-smoke: #f7f7f7;
$black: #000;
$purple: #4b1f67;
$gray: #e3e3e3;
$gray-shady: #979797;
$gray-light: #efefef;
$gray-dark: #333;
$orange: #ee7300;
$green: #c0ce00;
$red: #e9495a;
$blue: #27bada;

$site-colors: (
	'black': $black,
	'white': $white,
	'white-smoke': $white-smoke,
	'purple': $purple,
	'gray': $gray,
	'gray-shady': $gray-shady,
	'gray-light': $gray-light,
	'gray-dark': $gray-dark,
	'orange': $orange,
	'green': $green,
	'red': $red,
	'blue': $blue
) !default;

//----------  Bootstrap grid
//------------------------------------------------------------------------------

$enable-grid-classes: true !default;

//----------  Media queries breakpoints - Grid breakpoint
//------------------------------------------------------------------------------

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1020px,
	xl: 1360px
) !default;

//----------  Grid containers
//------------------------------------------------------------------------------

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
) !default;

//----------  Grid columns
//------------------------------------------------------------------------------

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

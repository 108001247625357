//----------  helpers
//------------------------------------------------------------------------------

* {
	box-sizing: border-box;
	&::before,
	&::after {
		box-sizing: border-box;
	}
}

%clearfix {
	&::before, &::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}

.centered {
	text-align: center;
}

.sr-only {
	@include visually-hidden;
}

.has-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.mobile-hidden {
	@include breakpoint(phone) {
		display: none;
	}
}

// Theming and colors iterator
@each $colors-name in $site-colors {
	$name: nth($colors-name, 1);
	$color: nth($colors-name, 2);
	.bg-#{$name} {
		background-color: $color;
	}
	.color-#{$name} {
		color: $color;
	}
}
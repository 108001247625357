//---------- destinations
//------------------------------------------------------------------------------
// sass-lint:disable no-vendor-prefixes
.destinations {
	position: relative;
	.slick-arrow {
		width: 50px;
		height: 50px;
		border: 1px solid $white;
		color: $white;
		font-size: 18px;
		position: absolute;
		top: 73px;
		z-index: 9;
		&::after {
			font-size: 16px;
		}
	}
	.slick-prev {
		right: 90px;
		left: auto;
	}
	.slick-next {
		right: 12px;
	}
	@include breakpoint(tablet) {
		.slick-arrow {
			top: 50px;
		}
		.slick-prev {
			right: 101px;
		}
		.slick-next {
			right: 23px;
		}
		.slick-next,
		.slick-prev {
			// box-shadow: 0 0 15px $gray-dark;
			background-color: rgba($gray-dark, .5);
		}
	}
	@media only screen and (max-width: 767px) {
		// top: 130px;
		.slick-arrow {
			top: 40px;
		}
		.slick-next,
		.slick-prev {
			// box-shadow: 0 0 15px $gray-dark;
			background-color: rgba($gray-dark, .5);
		}
		.slick-prev {
			right: 80px;
		}
	}
	@media only screen and (max-width: 480px) {
		// top: 130px;
		.slick-arrow {
			top: 55px;
		}
		.slick-next,
		.slick-prev {
			// box-shadow: 0 0 15px $gray-dark;
			background-color: rgba($gray-dark, .5);
		}
	}
}
.destinations__btn {
	margin-bottom: 30px;
	@include breakpoint(desktop) {
		margin-bottom: 62px;
	}
	@include breakpoint(landscape) {
		margin-bottom: 60px;
	}
}
.destinations__main-slide, .destinations__second-slide {
	height: 806px;
	background-color: $purple;
	color: $white;
	.wrap {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		position: relative;
	}
}
.destinations__main-slide {
	@include breakpoint(landscape) {
		height: 734px;
	}
	@include breakpoint(tablet) {
		height: 895px;
	}
	@media only screen and (max-width: 767px) {
		height: 364px;
	}
	@media only screen and (max-width: 480px) {
		height: 655px;
	}
}
.destinations__second-slide {
	@include breakpoint(landscape) {
		height: 806px;
	}
	@include breakpoint(tablet) {
		height: 806px;
		.destinations__btn {
			margin-bottom: 62px;
		}
	}
	@include breakpoint(phone) {
		height: 524px;
		.btn {
			margin-bottom: 70px;
		}
	}
}
.destinations__main-title {
	text-transform: uppercase;
	font-family: $font-bodoni;
	font-size: 167px;
	line-height: 123px;
	letter-spacing: -0.02em;
	span {
		letter-spacing: -0.02em;
	}
	@include breakpoint(landscape) {
		font-size: 120px;
		line-height: 93px;
	}
	@include breakpoint(tablet) {
		font-size: 90px;
		line-height: 70px;
	}
	@media only screen and (max-width: 767px) {
		font-size: 30px;
		line-height: 50px;
		transform: rotate(-90deg);
		position: absolute;
		left: 0;
		top: 120px;
	}
	@media only screen and (max-width: 480px) {
		font-size: 65px;
		line-height: 50px;
		transform: rotate(-90deg);
		position: absolute;
		left: -193px;
		top: 37.5%;
	}
}
.destinations__nav {
	display: flex;
	margin: 71px 0 78px;
	.btn {
		font-family: $font-bodoni;
		text-transform: uppercase;
		font-size: 30px;
		letter-spacing: -0.009em;
		line-height: 22px;
		height: 83px;
		min-width: 272px;
		margin: 0 33px;
		color: $white;
		border-color: rgba($white, .35);
		@include hover {
			transform: scale(1.05);
			border-color: $white;
			border-radius: 0;
			background-color: transparent;
		}
	}
	@include breakpoint(dekstop) {
		.btn {
			&:first-of-type {
				margin-left: 26px;
			}
		}
	}
	@include breakpoint(landscape) {
		margin: 57px 0 108px;
		.btn {
			min-width: 225px;
			font-size: 24px;
			margin: 0 7px;
			line-height: 18px;
			height: 66px;
			&:first-of-type {
				margin-left: 10px;
			}
		}
	}
	@include breakpoint(tablet) {
		flex-wrap: wrap;
		justify-content: center;
		max-width: 605px;
		margin-bottom: 100px;
		.btn {
			min-width: 272px;
			font-size: 27px;
			margin: 28px 0;
			line-height: 22px;
			&:nth-child(2n + 1) {
				margin-right: 56px;
			}
		}
	}
	@media only screen and (max-width: 767px) {
		margin: 0;
		flex-direction: column;
		margin-bottom: 154px;
		position: absolute;
		left: 50%;
		top: 50px;
		transform: translateX(-50%);
		margin-top: -20px;
		.btn {
			min-width: 153px;
			font-size: 14px;
			margin-bottom: 15px;
			line-height: 12px;
			height: 47px;
		}
	}
	@media only screen and (max-width: 480px) {
		margin-top: 0;
		margin: 0;
		flex-direction: column;
		margin-bottom: 154px;
		position: absolute;
		right: -20px;
		top: 47%;
		left: auto;
		transform: translate(0, -50%);
		.btn {
			min-width: 153px;
			font-size: 14px;
			margin-bottom: 15px;
			line-height: 12px;
			height: 47px;
		}
	}
}
.destinations__descr {
	font-size: 40px;
	line-height: 44px;
	margin-bottom: 118px;
	max-width: 786px;
	font-weight: 800;
	@include breakpoint(landscape) {
		font-size: 40px;
		margin-bottom: 120px;
	}
	@include breakpoint(tablet) {
		font-size: 34px;
		margin-bottom: 115px;
		padding: 0 25px;
	}
	@media only screen and (max-width: 759px) {
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 100px;
		padding: 0 50px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			width: 100%;
		}
	}
	@media only screen and (max-width: 400px) {
		margin-bottom: 60px;
		padding: 0 5px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			width: 100%;
		}
	}
}

.destinations__slide {
	height: 806px;
	@include breakpoint(landscape) {
		height: 734px;
		.destinations__btn {
			margin-bottom: 40px;
		}
	}
	@include breakpoint(tablet) {
		height: 1100px;
	}
	@media only screen and (max-width: 759px) {
		height: 636px;
	}
	@media only screen and (max-width: 400px) {
		height: 686px;
	}
}
.destinations__slide--second {
	@include breakpoint(landscape) {
		height: 1300px;
		.row {
			max-width: 660px;
			padding: 0;
			margin: 0 auto;
			[class^='col-'] {
				padding: 0;
			}
		}
		.img {
			width: 643px;
			// max-height: 294px;
			margin-top: 5px;
			.destinations__image {
				margin: 0 auto;
			}
		}
	}
	@include breakpoint(tablet) {
		height: 1450px;
		.img {
			width: 98%;
			// max-height: 294px;
			margin-top: 50px;
			margin-bottom: 43px;
			.destinations__image {
				margin: 0 auto;
			}
		}
	}
	@include breakpoint(phone) {
		height: auto;
		.row {
			[class^='col-'] {
				padding: 0 40px 0 35px;
				margin: 0;
				&:first-child {
					padding: 0;
				}
			}
		}
		.img {
			max-width: 320px;
			height: 210px;
			margin: 0 auto 35px;
		}
	}
}
.destinations__slide--orange {
	background-color: $orange;
	.destinations__title--vertical {
		left: -250px;
	}
	@include breakpoint(landscape) {
		.destinations__title--vertical {
			left: -240px;
		}
	}
	@include breakpoint(tablet) {
		.destinations__title--vertical {
			left: -225px;
		}
	}
	@include breakpoint(phone) {
		.destinations__title--vertical {
			left: 50%;
		}
	}
}
.destinations__slide--green {
	background-color: $green;
}
.destinations__slide--red {
	background-color: $red;
	.destinations__subtitle {
		max-width: 400px;
	}
	@include breakpoint(landscape) {
		.destinations__subtitle {
			max-width: 100%;
		}
	}
}
.destinations__slide--blue {
	background-color: $blue;
	background-size: auto;
	background-position: -85px center;
	.destinations__text {
		margin-bottom: 10px;
	}
	.destinations__title--vertical {
		left: -260px;
	}
	@media screen and (min-width: 1660px) {
		.destinations__bg-img {
			background-size: 99%;
			background-position: 85px center;
		}
	}
	@include breakpoint(phone) {
		.destinations__title--vertical {
			left: 50%;
		}
	}
}
.destinations__bg-img {
	height: 100%;
	max-width: 1596px;
	// margin-right: 77px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	position: relative;
	.destinations__bg-img-inner {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		left: 0;
		top: 0;
		padding-bottom: 59.4%;
		width: 100%;
		height: 100%;
	}
	.wrap {
		max-width: 1300px;
		margin: 0;
		margin-left: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin: 0 auto;
		}
	}
	.destinations__btn {
		margin-top: auto;
		margin-left: 10px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin-top: 0;
		}
	}
	@include breakpoint(landscape) {
		.wrap {
			align-items: flex-start;
		}
		.destinations__btn {
			margin-left: 155px;
		}
	}
	@include breakpoint(tablet) {
		height: 734px;
		width: 100%;
		margin-right: 0;
		.destinations__btn {
			margin-bottom: 38px;
		}
	}
	@include breakpoint(phone) {
		height: 286px;
		width: 100%;
		margin-right: 0;
	}
}
.destinations__bg {
	height: 100%;
	max-width: 1596px;
	// margin-right: 77px;
	margin: 0 auto;
	.wrap {
		max-width: 1140px;
		margin-right: 84px;
		margin-left: auto;
		padding: 45px 0;
	}
	[class^='col-'] {
		padding: 0 25px;
		&:first-child {
			padding-left: 42px;
		}
	}
	@include breakpoint(landscape) {
		[class^='col-'] {
			padding: 0 25px;
			margin-left: 50px;
			&:first-child {
				padding-left: 0;
			}
		}
	}
	@include breakpoint(tablet) {
		margin: 0;
		[class^='col-'] {
			padding: 0 78px;
			&:first-child {
				padding-left: 78px;
			}
		}
	}
	@include breakpoint(phone) {
		margin: 0;
		.wrap {
			padding-top: 96px;
		}
		[class^='col-'] {
			padding: 0 25px;
			margin-left: 42px;
			&:first-child {
				padding-left: 0;
			}
		}
	}
}
.destinations__title {
	font-family: $font-bodoni;
	text-transform: uppercase;
	&.destinations__title--vertical {
		transform: rotate(-90deg);
		color: $white;
		font-size: 100px;
		line-height: 74px;
		letter-spacing: -0.03em;
		text-align: center;
		position: absolute;
		left: -240px;
		top: 41%;
	}
	&.destinations__title--horizontal {
		font-size: 64px;
		line-height: 50px;
		letter-spacing: -0.03em;
		margin: 23px 0 15px;
	}
	@include breakpoint(landscape) {
		&.destinations__title--vertical {
			font-size: 90px;
			line-height: 68px;
			left: -230px;
			top: 40.5%;
		}
		&.destinations__title--horizontal {
			margin: 30px 0 40px -20px;
			text-align: center;
		}
	}
	@include breakpoint(tablet) {
		&.destinations__title--vertical {
			font-size: 90px;
			line-height: 68px;
			left: -215px;
		}
		&.destinations__title--horizontal {
			text-align: center;
			font-size: 60px;
			line-height: 44px;
			margin-bottom: 40px;
		}
	}
	@include breakpoint(phone) {
		&.destinations__title--vertical {
			transform: rotate(0) translate(-50%, -50%);
			font-size: 40px;
			line-height: 30px;
			top: 50%;
			left: 50%;
		}
		&.destinations__title--horizontal {
			font-size: 34px;
			line-height: 26px;
			text-align: center;
			margin-bottom: 40px;
		}
	}
}
.destinations__box {
	width: 348px;
	padding: 26px 26px 35px 20px;
	color: $white;
	position: absolute;
	left: 66%;
	&.destinations__box--orange {
		background-color: $orange;
		padding: 16px 26px 52px 20px;
		@include breakpoint(desktop) {
			left: 67.7%;
			bottom: 0;
		}
		.destinations__box-title {
			margin-bottom: 21px;
		}
	}
	&.destinations__box--green {
		background-color: $green;
		color: $black;
		.destinations__box-title {
			margin-bottom: 23px;
		}
		@include breakpoint(desktop) {
			top: 0;
		}
	}
	&.destinations__box--red {
		background-color: $red;
		padding-bottom: 70px;
		.destinations__box-title {
			margin-bottom: 43px;
		}
		@include breakpoint(desktop) {
			top: 0;
		}
	}
	&.destinations__box--blue {
		background-color: $blue;
		max-width: 300px;
		padding: 47px 30px 30px;
		.destinations__box-title {
			margin-bottom: 20px;
		}
		@include breakpoint(desktop) {
			top: 0;
			left: 76.5%;
		}
	}
	@include breakpoint(landscape) {
		left: auto;
		right: -75px;
		width: 407px;
		font-size: 13px;
		&.destinations__box--orange {
			padding: 26px 50px 37px 25px;
		}
		&.destinations__box--blue {
			max-width: 407px;
		}
	}
	@include breakpoint(tablet) {
		height: 365px;
		width: 100%;
		left: 0;
		top: auto;
		bottom: -50%;
		z-index: 10;
		padding: 36px 52px 36px 42px;
		&.destinations__box--orange {
			left: 0;
			bottom: -50%;
			padding: 36px 52px 36px 42px;
			.destinations__box-title {
				margin-bottom: 36px;
			}
		}
		&.destinations__box--green, &.destinations__box--red, &.destinations__box--blue {
			top: auto;
		}
		&.destinations__box--blue {
			max-width: 100%;
			left: 0;
			bottom: -50%;
			padding: 36px 52px 36px 42px;
		}
	}
	@media only screen and (max-width: 759px) {
		height: 350px;
		width: 100%;
		left: 0;
		top: auto;
		bottom: -350px;
		z-index: 10;
		padding: 22px 24px;
		&.destinations__box--orange {
			padding: 22px 24px;
		}
		&.destinations__box--blue {
			max-width: 100%;
			padding: 22px 24px;
		}
	}
	@media only screen and (max-width: 400px) {
		height: 400px;
		bottom: -400px;
	}
}

.destinations__box-title {
	font-family: $font-bodoni;
	font-size: 24px;
	line-height: 20px;
	letter-spacing: -0.06em;
	text-transform: uppercase;
	max-width: 200px;
	@include breakpoint(landscape) {
		font-size: 26px;
	}
	@include breakpoint(tablet) {
		font-size: 32px;
		line-height: 25px;
		max-width: 240px;
	}
}

.destinations__blockquote {
	margin-bottom: 20px;
	line-height: 18px;
	font-weight: 100;
	letter-spacing: 0.01em;
	@include breakpoint(tablet) {
		font-size: 18px;
		line-height: 22px;
	}
}

.destinations__author {
	font-weight: 800;
	@include breakpoint(tablet) {
		font-size: 18px;
		line-height: 22px;
	}
}

.destinations__town {
	max-width: 455px;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.008em;
	font-weight: 900;
	@include breakpoint(landscape) {
		max-width: 100%;
		padding-right: 10px;
	}
	@include breakpoint(tablet) {
		max-width: 100%;
		margin-bottom: 64px;
	}
	@include breakpoint(phone) {
		font-size: 15px;
		line-height: 20px;
		padding: 0 40px 0 35px;
		max-width: 100%;
		margin-bottom: 38px;
	}
}

.img {
	margin-top: 10px;
}

.destinations__subtitle {
	font-size: 18px;
	line-height: 22px;
	color: $purple;
	max-width: 430px;
	padding-bottom: 13px;
	margin-bottom: 18px;
	position: relative;
	font-weight: 800;
	&::after {
		content: '';
		width: 535px;
		height: 1px;
		background-color: $purple;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	@include breakpoint(landscape) {
		max-width: 100%;
		margin-top: 40px;
		margin-bottom: 16px;
		padding-bottom: 15px;
		&::after {
			width: 100%;
		}
	}
	@include breakpoint(tablet) {
		display: none;
	}
	@include breakpoint(phone) {
		display: none;
	}
}

.destinations__subtitle-second, .destinations__text {
	font-size: 13px;
	line-height: 18px;
	color: $purple;
	@include breakpoint(tablet) {
		font-size: 15px;
		line-height: 20px;
	}
	@include breakpoint(phone) {
		font-size: 15px;
		line-height: 20px;
	}
}

.destinations__subtitle-second {
	font-weight: 800;
}

.destinations__text {
	margin-bottom: 18px;
	letter-spacing: -0.0028em;
	@include breakpoint(tablet) {
		margin-bottom: 20px;
	}
}

.destinations__goback {
	border: 1px solid $white;
	color: $white;
	display: inline-block;
	font-size: 22px;
	height: 50px;
	line-height: 1;
	padding: 14px;
	position: absolute;
	top: 150px;
	right: 12px;
	transform: translate(0, -50%);
	width: 50px;
	z-index: 9;
	@include breakpoint(tablet) {
		right: 23px;
		top: 120px;
	}
	@media only screen and (max-width: 767px) {
		top: 105px;
	}
	@media only screen and (max-width: 480px) {
		top: 130px;
	}
}

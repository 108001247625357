//----------  layout
//------------------------------------------------------------------------------

body {
	font-family: $font-default;
	font-size: 14px;
}

.wrap {
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 20px;
	width: 100%;
}

//---------- error-page
//------------------------------------------------------------------------------

.error-page {
	height: 1024px;
	.btn {
		min-width: 184px;
	}
	@include breakpoint(landscape) {
		height: 768px;
	}
	@include breakpoint(phone) {
		height: 568px;
		.btn {
			height: 50px;
			line-height: 50px;
			font-size: 17px;
		}
	}
}
.error__img, .error__content {
	height: 100%;
	background: linear-gradient(rgba($black, .5)), url('/assets/images/error-page.jpg') no-repeat center;
	background-size: cover;
}
.error__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.error__title {
	font-size: 100px;
	letter-spacing: 0.007em;
	font-family: $font-bodoni;
	text-transform: uppercase;
	color: $white;
	margin-top: 150px;
	@include breakpoint(landscape) {
		font-size: 80px;
		margin-top: 63px;
	}
	@include breakpoint(tablet) {
		font-size: 80px;
		line-height: 62px;
		margin-top: 52px;
	}
	@include breakpoint(phone) {
		font-size: 40px;
		line-height: 32px;
		margin-top: 22px;
	}
}
.error__text {
	font-size: 22px;
	letter-spacing: 0.003em;
	font-weight: 800;
	line-height: 32px;
	color: $white;
	max-width: 550px;
	text-align: center;
	margin: 21px 0 105px;
	@include breakpoint(landscape) {
		margin: 28px 0 80px;
	}
	@include breakpoint(tablet) {
		margin: 90px 0 95px;
	}
	@include breakpoint(phone) {
		font-size: 18px;
		line-height: 26px;
		margin: 45px 0 52px;
		padding: 0 33px;
	}
}
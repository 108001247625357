//---------- download
//------------------------------------------------------------------------------

.download {
	.wrap {
		max-width: 1200px;
	}
	.btn {
		margin-left: 48px;
	}
	@include breakpoint(landscape) {
		.wrap {
			max-width: 940px;
		}
		.btn {
			margin-left: 30px;
			min-width: 276px;
		}
	}
	@include breakpoint(tablet) {
		[class^='col-'] {
			padding: 0;
		}
		.btn {
			margin-left: 55px;
		}
	}
}
.download__top {
	margin-bottom: -194px;
	padding-top: 38px;
	@include breakpoint(landscape) {
		margin-bottom: -155px;
		padding-top: 100px;
	}
	@include breakpoint(tablet) {
		margin-bottom: -100px;
		padding-top: 30px;
	}
	@include breakpoint(phone) {
		margin-bottom: -145px;
		padding: 0;
		.row {
			display: flex;
			flex-direction: column;
			align-items: center;
			.download__title {
				order: 1;
				margin: 0;
			}
			.download__img {
				order: 2;
				max-width: 275px;
				margin: 25px 0 35px;
			}
			.btn {
				order: 3;
				margin: 0;
			}
		}
	}
}
.download__img {
	width: 635px;
	margin-left: 5px;
	@include breakpoint(landscape) {
		width: 500px;
	}
	@include breakpoint(tablet) {
		margin-left: 30px;
	}
}
.download__title {
	margin: 108px 0 23px 48px;
	@include breakpoint(landscape) {
		margin: 59px 0 18px 30px;
		font-size: 40px;
	}
	@include breakpoint(tablet) {
		margin: 59px 0 18px 55px;
		font-size: 40px;
	}
}
.download__bottom {
	background-color: $white-smoke;
}
.download__table {
	padding: 231px 0 60px;
	[class^='col-'] {
		padding: 0;
	}
	@include breakpoint(landscape) {
		padding: 218px 0 64px;
	}
	@include breakpoint(tablet) {
		padding: 170px 0 64px;
		[class^='col-'] {
			&:first-child {
				.download__table-title, .download__item {
					padding-right: 25px;
					padding-left: 60px;
				}
			}
		}
	}
	@include breakpoint(phone) {
		padding: 200px 0 73px;
	}
}
.download__table-title {
	height: 63px;
	padding: 18px 14px 18px 12px;
	font-size: 20px;
	background-color: $white-smoke;
	@include breakpoint(landscape) {
		font-size: 16px;
		height: 49px;
		padding: 16px 12px 12px 7px;
	}
	@include breakpoint(tablet) {
		padding: 18px 55px 15px 30px;
	}
	@include breakpoint(phone) {
		font-size: 17px;
		padding: 18px 14px 18px 7px;
	}
}
.download__item {
	height: 63px;
	padding: 18px 14px 18px 12px;
	position: relative;
	display: flex;
	justify-content: space-between;
	&:nth-child(2n + 1) {
		background-color: rgba($gray-shady, .08);
	}
	@include breakpoint(landscape) {
		height: 49px;
		padding: 16px 12px 15px 7px;
	}
	@include breakpoint(tablet) {
		padding: 16px 55px 15px 30px;
	}
	@include breakpoint(phone) {
		height: 56px;
		padding: 16px 12px 15px 7px;
	}
}
.download__link {
	width: 100%;
	font-size: 20px;
	color: $purple;
	&::after {
		float: right;
		color: $black;
	}
	@include breakpoint(landscape) {
		font-size: 16px;
		letter-spacing: -0.01em;
	}
	@include breakpoint(tablet) {
		&::after {
			margin-top: 6px;
		}
	}
	@include breakpoint(phone) {
		font-size: 17px;
		&::after {
			margin-top: 4px;
		}
	}
}
.split {
	column-count: 2;
	column-gap: 0;
	@include breakpoint(tablet) {
		column-count: 1;
		.download__list {
			&:nth-child(2n) {
				.download__item {
					&:nth-child(2n + 1) {
						background-color: $white-smoke;
					}
					&:nth-child(2n) {
						background-color: rgba($gray-shady, .08);
					}
				}
			}
		}
	}
	@include breakpoint(phone) {
		column-count: 1;
		.download__list {
			&:nth-child(2n) {
				.download__item {
					&:nth-child(2n + 1) {
						background-color: $white-smoke;
					}
					&:nth-child(2n) {
						background-color: rgba($gray-shady, .08);
					}
				}
			}
		}
	}
}
//---------- cookie
//------------------------------------------------------------------------------

.cookie {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1;
	background-color: $white;
	display: none;
	.wrap {
		display: flex;
		max-width: 1208px;
		padding-top: 33px;
		padding-bottom: 38px;
	}
	strong {
		color: $purple;
	}
	@include breakpoint(landscape) {
		.wrap {
			max-width: 980px;
		}
	}
	@include breakpoint(phone) {
		.wrap {
			flex-direction: column;
			padding: 44px 20px;
			align-items: center;
		}
	}
}
.cookie__text {
	flex: 1;
	font-size: 16px;
	line-height: 22px;
	color: $black;
	padding-right: 30px;
	@include breakpoint(landscape) {
		font-size: 14px;
		padding-right: 50px;
	}
	@include breakpoint(phone) {
		font-size: 14px;
		padding-right: 0;
		padding-left: 13px;
		margin-bottom: 45px;
	}
}
.cookie__url {
	color: $purple;
	border-bottom: 1px solid $purple;
	@include hover {
		text-decoration: none;
	}
}
.cookie__btn {
	width: 132px;
	height: 50px;
	border: 1px solid $black;
	font-size: 17px;
	font-weight: 700;
}

//---------- about
//------------------------------------------------------------------------------
// sass-lint:disable no-important, no-vendor-prefixes
.about {
	br {
		line-height: 50px;
	}
	.wrap {
		max-width: 950px;
		padding-top: 50px;
		padding-bottom: 40px;
		text-align: center;
	}
	@include breakpoint(tablet) {
		.wrap {
			padding-bottom: 32px;
		}
	}
	@include breakpoint(phone) {
		.wrap {
			padding-top: 45px;
			padding-bottom: 28px;
		}
		br {
			line-height: 40px;
		}
	}
}
.about__text-wrapper {
	margin: 63px 0 118px;
	display: flex;
	font-weight: 800;
	@include breakpoint(tablet) {
		flex-direction: column;
		margin: 55px 0 120px;
	}
	@include breakpoint(phone) {
		flex-direction: column;
		margin: 38px 0 100px; 
	}
}
.about__intro, .about__descr {
	flex: 1 1 auto;
	color: $purple;
	text-align: left;
}
.about__intro {
	font-size: 26px;
	line-height: 34px;
	flex-basis: 56.5%;
	padding-right: 40px;
	@include breakpoint(tablet) {
		padding: 0 76px;
	}
	@include breakpoint(phone) {
		font-size: 16px;
		line-height: 26px;
		padding: 0;
		margin-bottom: 35px;
	}
}
.about__descr {
	font-size: 16px;
	line-height: 25px;
	margin-top: 5px;
	padding-right: 18px;
	flex-basis: 43.5%;
	@include breakpoint(tablet) {
		padding: 0 76px;
		margin-top: 37px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin: 180px 0 100px;
		}
	}
	@include breakpoint(phone) {
		font-size: 14px;
		line-height: 20px;
		padding: 0;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin: 150px 0;
		}
	}
}
.about__text {
	&:first-child {
		margin-bottom: 25px;
	}
}
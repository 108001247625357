//----------  footer
//------------------------------------------------------------------------------
.footer {
	border-top: 15px solid #1b1b1d;
	background-color: #3c3c3c;
	color: $white;
	position: relative;
}
.footer__top {
	padding: 39px 10px 58px;
	max-width: 1140px;
	@include breakpoint(landscape) {
		padding-right: 50px;
	}
	@include breakpoint(tablet) {
		padding: 38px 70px 37px;
	}
	@include breakpoint(phone) {
		padding: 38px 30px 37px;
	}
}
.footer__subtitle {
	margin-bottom: 28px;
	font-size: 18px;
}
.footer__contact {
	padding: 0;
	&:first-child {
		padding-left: 15px;
	}
	@include breakpoint(landscape) {
		&:first-child {
			padding-left: 52px;
		}
		&:last-child {
			margin-left: -20px;
		}
	}
	@include breakpoint(tablet) {
		margin-bottom: 40px; 
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			margin-left: -60px;
		}
	}
	@include breakpoint(phone) {
		margin-bottom: 45px; 
		&:first-child {
			padding-left: 0;
		}
	}
}
.footer__social-item, .footer__social-link {
	font-size: 17px;
	color: $gray-shady;
	&::after {
		float: left;
		font-size: 27px;
		margin: 0 8px 0 3px;
	}
}
.social-link__space {
	padding-top: 20px;
	display: block;
}
.footer__social-item {
	margin-bottom: 9px;
}
.footer__symbol {
	font-size: 14px;
	vertical-align: top;
}
.footer__logos {
	padding: 0 37px 0 0;
	@include breakpoint(landscape) {
		padding: 0 0 0 70px;
	}
	@include breakpoint(tablet) {
		padding-right: 100px;
	}
	@include breakpoint(phone) {
		padding: 0;
	}
}
.footer__block {
	padding-bottom: 30px;
	margin-bottom: 19px;
	&:first-child {
		padding-bottom: 35px;
		border-bottom: 1px solid $gray-shady;
	}
	.footer__subtitle {
		margin-bottom: 15px;
	}
	@include breakpoint(landscape) {
		&:first-child {
			padding-bottom: 0;
		}
	}
}
.footer__brand {
	display: inline-block;
	margin-right: 35px;
	&:last-child {
		margin: 0;
	}
	@include breakpoint(landscape) {
		margin-bottom: 30px;
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@include breakpoint(phone) {
		flex-basis: 90px;
		margin-bottom: 25px;
	}
}
.scroll-to-top {
	display: none;
	position: fixed;
	right: 10px;
	bottom: 55px;
	z-index: 1000;
	@include breakpoint(phone) {
		bottom: 20px;
	}
}
.footer__bottom {
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&::before {
		content: '';
		height: 1px;
		width: 100%;
		background-color: $gray-shady;
		position: absolute;
		left: 0;
		bottom: 48px;
	}
	&.wrap {
		padding: 15px 8px;
		max-width: 1110px;
	}
	@include breakpoint(landscape) {
		&::before {
			bottom: 57px;
		}
		&.wrap {
			padding: 16px 50px 26px;
		}
	}
	@include breakpoint(tablet) {
		flex-direction: column;
		align-items: flex-start;
		&::before {
			bottom: 105px;
		}
		&.wrap {
			padding: 24px 65px 28px;
		}
	}
	@include breakpoint(phone) {
		flex-direction: column;
		align-items: flex-start;
		&::before {
			bottom: 190px;
		}
		&.wrap {
			padding: 24px 0 35px 30px;
		}
	}
}

.footer__copyright {
	padding-left: 7px;
	letter-spacing: 0.02em;
	color: $gray-shady;
	@include breakpoint(tablet) {
		order: 2;
		font-size: 14px;
		padding: 0;
	}
	@include breakpoint(phone) {
		order: 2;
		font-size: 14px;
		padding: 0;
	}
}
.footer__privacy {
	@include breakpoint(tablet) {
		order: 1;
		margin-bottom: 18px;
	}
	@include breakpoint(phone) {
		order: 1;
		margin-bottom: 18px;
	}
}
.footer__item {
	display: inline-block;
	margin-right: 14px;
	&:last-child {
		margin: 0;
	}
	@include breakpoint(phone) {
		margin-bottom: 13px;
	}
}
.footer__link {
	color: $gray-shady;
	@include breakpoint(tablet) {
		letter-spacing: -0.005em;
	}
}
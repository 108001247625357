//----------  reset
//------------------------------------------------------------------------------
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
	display: block;
}

audio:not([controls]) {
	display: none;
}

[hidden] {
	display: none;
}

//----------  base reset
//------------------------------------------------------------------------------
html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	font-size: 1em;
	line-height: 1.35;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

//----------  links
//------------------------------------------------------------------------------
a {
	text-decoration: none;
}

a:focus,
a:hover,
a:active {
	outline: none;
}

//----------  typography
//------------------------------------------------------------------------------
b,
strong {
	font-weight: bold;
}

blockquote {
	margin: 0;
}

//----------  lists
//------------------------------------------------------------------------------
nav ul,
ul,
li {
	list-style: none;
	list-style-image: none;
	margin: 0;
	padding: 0;
}

button {
	border: 0;
	cursor: pointer;
	-webkit-appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input {
	outline: none;
}
@font-face {
	font-family: 'svgicons';
	src: url('../assets/fonts/svgicons.woff2') format('woff2'),
		url('../assets/fonts/svgicons.woff') format('woff'),
		url('../assets/fonts/svgicons.ttf') format('truetype');
}

@mixin font-styles {
	font-family: 'svgicons';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	display: block;
	line-height: 1;
}

%font {
	@include font-styles;
}

@function font-char($filename) {
	$char: "";

	@if $filename == ico-arrow-down {
		$char: '\E001';
	}
	@if $filename == ico-arrow-right {
		$char: '\E002';
	}
	@if $filename == ico-arrow-top {
		$char: '\E003';
	}
	@if $filename == ico-close {
		$char: '\E004';
	}
	@if $filename == ico-download {
		$char: '\E005';
	}
	@if $filename == ico-fullscreen {
		$char: '\E006';
	}
	@if $filename == ico-goback {
		$char: '\E007';
	}
	@if $filename == ico-hamburger {
		$char: '\E008';
	}
	@if $filename == ico-linkedin {
		$char: '\E009';
	}
	@if $filename == ico-slider-arrow-left {
		$char: '\E00A';
	}
	@if $filename == ico-slider-arrow-right {
		$char: '\E00B';
	}
	@if $filename == ico-slider-destinations-arrow-left {
		$char: '\E00C';
	}
	@if $filename == ico-slider-destinations-arrow-right {
		$char: '\E00D';
	}

	@return $char;
}

@mixin font($filename, $insert: after, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %font;
		} @else {
			@include font-styles;
		}
		content: font-char($filename);
	}
}

[class*='font-ico'] {
	display: inline-block;
}

.font-ico-arrow-down {
	@include font(ico-arrow-down);
}
.font-ico-arrow-right {
	@include font(ico-arrow-right);
}
.font-ico-arrow-top {
	@include font(ico-arrow-top);
}
.font-ico-close {
	@include font(ico-close);
}
.font-ico-download {
	@include font(ico-download);
}
.font-ico-fullscreen {
	@include font(ico-fullscreen);
}
.font-ico-goback {
	@include font(ico-goback);
}
.font-ico-hamburger {
	@include font(ico-hamburger);
}
.font-ico-linkedin {
	@include font(ico-linkedin);
}
.font-ico-slider-arrow-left {
	@include font(ico-slider-arrow-left);
}
.font-ico-slider-arrow-right {
	@include font(ico-slider-arrow-right);
}
.font-ico-slider-destinations-arrow-left {
	@include font(ico-slider-destinations-arrow-left);
}
.font-ico-slider-destinations-arrow-right {
	@include font(ico-slider-destinations-arrow-right);
}

//----------  fonts
//------------------------------------------------------------------------------

@font-face {
	font-family: 'Avenir';
	src: url('../../assets/fonts/AvenirLTStd-Black.eot') format('eot'),
		 url('../../assets/fonts/AvenirLTStd-Black.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirLTStd-Black.woff') format('woff'),
		 url('../../assets/fonts/AvenirLTStd-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../assets/fonts/AvenirLTStd-Book.eot') format('eot'),
		 url('../../assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirLTStd-Book.woff') format('woff'),
		 url('../../assets/fonts/AvenirLTStd-Book.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../assets/fonts/AvenirLTStd-Heavy.eot') format('eot'),
		 url('../../assets/fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirLTStd-Heavy.woff') format('woff'),
		 url('../../assets/fonts/AvenirLTStd-Heavy.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../assets/fonts/AvenirLTStd-Light.eot') format('eot'),
		 url('../../assets/fonts/AvenirLTStd-Light.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirLTStd-Light.woff') format('woff'),
		 url('../../assets/fonts/AvenirLTStd-Light.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../assets/fonts/AvenirLTStd-Roman.eot') format('eot'),
		 url('../../assets/fonts/AvenirLTStd-Roman.woff2') format('woff2'),
		 url('../../assets/fonts/AvenirLTStd-Roman.woff') format('woff'),
		 url('../../assets/fonts/AvenirLTStd-Roman.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Bodoni';
	src: url('../../assets/fonts/BodoniStd-Poster.eot') format('eot'),
		 url('../../assets/fonts/BodoniStd-Poster.woff2') format('woff2'),
		 url('../../assets/fonts/BodoniStd-Poster.woff') format('woff'),
		 url('../../assets/fonts/BodoniStd-Poster.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: auto;
}
//----------  responsive breakpoints
//------------------------------------------------------------------------------

@mixin breakpoint ($value) {
	@if $value == 'phone' {
		@media only screen and (min-width: 320px) and (max-width: 759px) { @content; }
	} @else if $value == 'tablet' {
		@media only screen and (min-width: 760px) and (max-width: 1019px) { @content; }
	}  @else if $value == 'landscape' {
		@media only screen and (min-width: 1020px) and (max-width: 1359px) { @content; }
	} @else if $value == 'touch' {
		@media only screen and (min-width: 120px) and (max-width: 1024px) { @content; }
	} @else if $value == 'desktop' {
		@media only screen and (min-width: 1360px) { @content; }
	} @else {
		@media only screen and (max-width: $value) { @content; }
	}
}

//	Visually hidden mixin for accessibility
@mixin visually-hidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Hover mixin only for desktop devices
// to include use @include hover {your hover styles} can also include elements
@mixin hover {
	@media only screen and (min-width: 1025px) {
		.no-touch &:hover {
			@content;
		}
	}
}
//---------- creators
//------------------------------------------------------------------------------

.creators {
	.wrap {
		padding: 0 15px;
	}
	@include breakpoint(landscape) {
		.btn {
			min-width: 276px;
		}
	}
}
.creators__block {
	height: 810px;
	text-align: center;
	color: $white;
	padding: 52px 0 47px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include breakpoint(landscape) {
		height: 580px;
		padding: 30px 0 27px;
	}
	@include breakpoint(tablet) {
		height: 700px;
		font-size: 100px;
		padding: 40px 0 32px;
	}
	@include breakpoint(phone) {
		height: 567px;
		padding: 35px 0 31px;
	}
}
.creators__title {
	text-transform: uppercase;
	font-size: 26px;
	letter-spacing: 0.018em;
	font-weight: 900;
	@include breakpoint(phone) {
		font-size: 18px;
	}
}
.creators__subtitle {
	text-transform: uppercase;
	font-family: $font-bodoni;
	font-size: 86px;
	line-height: 65px;
	letter-spacing: -0.017em;
	margin-top: auto;
	margin-bottom: 48px;
	display: block;
	@include breakpoint(landscape) {
		font-size: 68px;
		line-height: 50px;
		margin-bottom: 27px;
	}
	@include breakpoint(tablet) {
		font-size: 100px;
		line-height: 75px;
		margin-bottom: 35px;
	}
	@include breakpoint(phone) {
		font-size: 50px;
		line-height: 38px;
		margin-bottom: 52px;
	}
}

//----------  header
//------------------------------------------------------------------------------
.header {
	width: 100%;
	position: fixed;
	z-index: 10;
	background-color: rgba($black, 0.78);
	border-bottom: 3px solid $white;
}
.header__container {
	@extend %clearfix;
	text-align: center;
	padding: 20px 25px 25px 23px;
	@include breakpoint(landscape) {
		padding: 20px 17px 25px 11px;
	}
	@include breakpoint(tablet) {
		padding: 20px 17px 25px 10px;
	}
	@include breakpoint(phone) {
		padding: 15px 0 10px;
	}
}
.logo__img {
	width: 135px;
	color: $white;
	@include breakpoint(phone) {
		width: 84px;
	}
}
.header__title {
	margin-left: -85px;
	color: $white;
	font-size: 22px;
	font-weight: 800;
	padding-top: 15px;
    display: inline-block;
	@include breakpoint(landscape) {
		margin-left: -80px;
	}
	@include breakpoint(tablet) {
		margin-left: -74px;
	}
	@include breakpoint(phone) {
		display: none;
	}
}
.header__toggle-btn {
	display: none;
	.icon {
		font-size: 25px;
	}
	@include breakpoint(phone) {
		.icon {
			font-size: 16px;
		}
	}
}
.logo {
	float: left;
}
.header--toggled {
	margin-top: 8px;
	float: right;
	width: 47px;
	color: $white;
	font-family: $font-default;
	font-size: 18px;
	font-weight: 800;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	@include breakpoint(phone) {
		width: 32px;
	}
}
.header__toggle-text {
	margin: 5px 0 -5px;
	@include breakpoint(phone) {
		font-size: 12px;
		margin-top: 3px;
	}
}
.nav {
	min-height: 100%;
	padding: 53px 230px 0 0;
	position: fixed;
	top: -200%;
	right: 0;
	will-change: transform;
	background-color: rgba($black, 0.78);
	transition: top 0.4s ease-out;
	@include breakpoint(landscape) {
		padding-right: 0;
	}
	@media only screen and (max-width: 767px) {
		overflow: scroll;
		max-height: 100vh;
		display: block;
		width: 100%;
		padding: 28px 0 0;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
		padding: 28px 0 0;
	}
}
.nav__opened {
	top: 106px;
	z-index: 10;
	@include breakpoint(phone) {
		top: 66px;
		height: calc(100vh - 66px);
		min-height: auto;
		max-height: none;
		overflow: hidden;
	}
}
.nav__list {
	width: 510px;
	text-align: center;
	@include breakpoint(phone) {
		width: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
.nav__link, .nav__link-out {
	color: $white;
	font-size: 30px;
	font-weight: 800;
	line-height: 68px;
	@include hover {
		font-weight: 900;
		text-decoration: none;
	}
	@include breakpoint(phone) {
		font-size: 22px;
		line-height: 1;
		padding-bottom: 8%;
		display: block;
	}
}

.nav__link-out {
	@include breakpoint(phone) {
		padding-bottom: 0;
	}
}

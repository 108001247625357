//----------  button
//------------------------------------------------------------------------------


button,
input[type='button'] {
	background: none;
	cursor: pointer;
	outline: none;
	padding: 0;
}

.btn {
	background-color: transparent;
	display: inline-block;
	text-align: center;
	font-size: 17px;
	font-weight: 800;
	height: 50px;
	line-height: 47px;
	min-width: 304px;
	padding: 0 20px;
	transition: 0.4s ease-out;
	border: 1px solid;
	&::after {
		display: inline-block;
		font-size: 20px;
		margin-left: 13px;
		vertical-align: middle;
	}
	@include hover {
		border-color: transparent;
		background-color: #4a4a4a;
		color: $white;
		border-radius: 25px;
		text-decoration: none;
	}
	@include breakpoint(tablet) {
		min-width: 276px;
	}
	@include breakpoint(phone) {
		height: 40px;
		line-height: 38px;
		min-width: 221px;
		font-size: 14px;
		&::after {
			font-size: 17px;
		}
	}
}
.btn--white {
	border-color: $white;
	color: $white;
}
.btn--black {
	border-color: $black;
	color: $black;
}
